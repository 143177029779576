//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UkFlag',
};
